<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filtered_shops"
      :items-per-page="10"
      :options="options"
      class="br-16 elevation-1"
      :loading="loading_shops"
      no-data-text="There is no shops associated with your brand."
    >
      <template v-slot:top>
        <div
          class="
            d-flex
            flex-sm-row flex-column
            justify-space-between
            align-sm-center align-stretch
            br-16
            pa-4
          "
        >
          <v-text-field
            v-model="_autocomplete"
            class="br-8 mr-sm-4 mb-4 mb-sm-0"
            placeholder="Filter shops by name"
            label="Name"
            style="max-width: 350px"
            prepend-inner-icon="search"
            hide-details
            outlined
            clearable
            dense
          />
          <v-select
            v-if="is_super_admin"
            v-model="_brand_id"
            class="br-8 mb-6 mb-sm-0"
            :items="brands.map((b) => ({ ...b, header: null }))"
            item-text="name"
            item-value="id"
            placeholder="Select brands"
            label="Brands"
            multiple
            style="max-width: 350px"
            hide-details
            outlined
            clearable
            dense
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="_brand_id.length === 1">{{ item.name }}</span>
              <span v-if="index === 1"> {{ _brand_id.length }} selected </span>
            </template>
          </v-select>
          <v-spacer />
          <div class="text-center text-no-wrap">
            <v-btn
              color="yellow"
              :block="$vuetify.breakpoint.xsOnly"
              rounded
              @click="export_users"
              class="mr-sm-3 mb-4 mb-sm-0"
            >
              <v-icon color="black" left>mdi-download</v-icon>Export
              Users</v-btn
            >
            <v-btn
              color="yellow"
              :block="$vuetify.breakpoint.xsOnly"
              rounded
              to="/admin/register"
              class="mb-4 mb-sm-0"
            >
              <v-icon color="black" left>mdi-plus</v-icon>Register new
              shop</v-btn
            >
          </div>
        </div>
      </template>
      <template v-slot:[`header.preview`]>
        <v-icon>mdi-eye</v-icon>
      </template>

      <template v-slot:[`item.brands`]="{ item }">
        <p class="mb-0">
          {{ item.brands.length || "0" }}
        </p>
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <a
          :href="shop_brand_url(item.id)"
          target="_blank"
          class="black--text no-text-decoration"
          ><v-icon>mdi-eye</v-icon></a
        >
      </template>
      <template v-slot:[`item.preview`]="{ item }">
        <a
          v-for="brand in item.brands"
          :key="`${item.id}_${brand.id}`"
          :href="preview_url(item.id, brand.id)"
          target="_blank"
          class="black--text mr-2"
          >{{ brand.name }}</a
        >
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn
          small
          icon
          :to="{ name: 'admin-shops-id', params: { id: item.id } }"
          ><v-icon>mdi-pencil-outline</v-icon></v-btn
        >
      </template>
      <template v-slot:[`item.reset`]="{ item }">
        <v-btn
          small
          icon
          @click="
            selected_shop = item;
            send_password_reset_request = true;
          "
          ><v-icon>mdi-lock-reset</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <AdminDialog
      v-if="send_password_reset_request"
      :show_dialog.sync="send_password_reset_request"
      header="Reset Password Request"
      @confirm="send_shop_admin_reset_password_email(selected_shop.id)"
      :loading="is_processing"
      confirm_text="Continue"
      confirm_color="primary"
    >
      <template v-slot:[`content`]>
        <p class="mb-0">
          Are you sure you want to send a password reset link to
          <b> {{ selected_shop.name }} </b>?
        </p>
      </template>
    </AdminDialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment";

import { export_shop_admins, reset_shop_admin_password } from "@/requests";

import AdminDialog from "@/components/base/AdminDialog";

export default {
  name: "AllShops",
  data() {
    return {
      export_shop_admins,
      search: null,
      selected_shop: null,
      options: {},
      send_password_reset_request: false,
      is_processing: false,
    };
  },
  components: {
    AdminDialog,
  },
  computed: {
    ...mapState("SuperAdminStore", [
      "brands",
      "loading_shops",
      "shops_filters",
    ]),
    ...mapState("UserStore", ["is_super_admin"]),
    ...mapGetters("SuperAdminStore", ["filtered_shops"]),
    headers() {
      if (this.is_super_admin) {
        return [
          { text: "Name", value: "name" },
          { text: "Brands", value: "brands" },
          { text: "#", value: "preview", align: "center", sortable: false },
          { text: "Edit", value: "edit", align: "center", sortable: false },
          {
            text: "Request Password Reset",
            value: "reset",
            align: "center",
            sortable: false,
          },
        ];
      }
      return [
        { text: "Name", value: "name" },
        { text: "Preview", value: "url", align: "center", sortable: false },
        { text: "Edit", value: "edit", align: "center", sortable: false },
        {
          text: "Request Password Reset",
          value: "reset",
          align: "center",
          sortable: false,
        },
      ];
    },
    _autocomplete: {
      get() {
        return this.shops_filters.autocomplete;
      },
      set(v) {
        this.set_shops_filter({ autocomplete: v });
        this.options = { page: 1 };
      },
    },
    _brand_id: {
      get() {
        return this.shops_filters.brand_id;
      },
      set(v) {
        this.set_shops_filter({ brand_id: v });
        this.options = { page: 1 };
      },
    },
  },

  methods: {
    ...mapActions("SuperAdminStore", ["set_shops_filter"]),
    preview_url(shop_id, brand_id) {
      const preview_url = process.env.VUE_APP_BRAND_PREVIEW_URL;
      return `${preview_url}/shop/${shop_id}?brand_id=${brand_id}`;
    },
    shop_brand_url(shop_id) {
      return `${this.brands[0].url}shop/${shop_id}`;
    },
    async export_users() {
      const response = await export_shop_admins();
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `users-export-${moment().format("YYYY-MM-DD")}.csv`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    async send_shop_admin_reset_password_email(shop_id) {
      this.is_processing = true;
      try {
        await reset_shop_admin_password(shop_id);
      } finally {
        this.send_password_reset_request = false;
        this.is_processing = false;
        this.selected_shop = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.color-preview {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 0 auto;
}
.logo-preview {
  margin: auto;
  height: 56px;
  width: 200px;
  background-position: center center;
  background-repeat: initial;
  background-size: contain, auto;
}
</style>
