var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"br-16 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filtered_shops,"items-per-page":10,"options":_vm.options,"loading":_vm.loading_shops,"no-data-text":"There is no shops associated with your brand."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"\n          d-flex\n          flex-sm-row flex-column\n          justify-space-between\n          align-sm-center align-stretch\n          br-16\n          pa-4\n        "},[_c('v-text-field',{staticClass:"br-8 mr-sm-4 mb-4 mb-sm-0",staticStyle:{"max-width":"350px"},attrs:{"placeholder":"Filter shops by name","label":"Name","prepend-inner-icon":"search","hide-details":"","outlined":"","clearable":"","dense":""},model:{value:(_vm._autocomplete),callback:function ($$v) {_vm._autocomplete=$$v},expression:"_autocomplete"}}),(_vm.is_super_admin)?_c('v-select',{staticClass:"br-8 mb-6 mb-sm-0",staticStyle:{"max-width":"350px"},attrs:{"items":_vm.brands.map(function (b) { return (Object.assign({}, b, {header: null})); }),"item-text":"name","item-value":"id","placeholder":"Select brands","label":"Brands","multiple":"","hide-details":"","outlined":"","clearable":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm._brand_id.length === 1)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',[_vm._v(" "+_vm._s(_vm._brand_id.length)+" selected ")]):_vm._e()]}}],null,false,4291661311),model:{value:(_vm._brand_id),callback:function ($$v) {_vm._brand_id=$$v},expression:"_brand_id"}}):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"text-center text-no-wrap"},[_c('v-btn',{staticClass:"mr-sm-3 mb-4 mb-sm-0",attrs:{"color":"yellow","block":_vm.$vuetify.breakpoint.xsOnly,"rounded":""},on:{"click":_vm.export_users}},[_c('v-icon',{attrs:{"color":"black","left":""}},[_vm._v("mdi-download")]),_vm._v("Export Users")],1),_c('v-btn',{staticClass:"mb-4 mb-sm-0",attrs:{"color":"yellow","block":_vm.$vuetify.breakpoint.xsOnly,"rounded":"","to":"/admin/register"}},[_c('v-icon',{attrs:{"color":"black","left":""}},[_vm._v("mdi-plus")]),_vm._v("Register new shop")],1)],1)],1)]},proxy:true},{key:"header.preview",fn:function(){return [_c('v-icon',[_vm._v("mdi-eye")])]},proxy:true},{key:"item.brands",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.brands.length || "0")+" ")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"black--text no-text-decoration",attrs:{"href":_vm.shop_brand_url(item.id),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return _vm._l((item.brands),function(brand){return _c('a',{key:((item.id) + "_" + (brand.id)),staticClass:"black--text mr-2",attrs:{"href":_vm.preview_url(item.id, brand.id),"target":"_blank"}},[_vm._v(_vm._s(brand.name))])})}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","to":{ name: 'admin-shops-id', params: { id: item.id } }}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}},{key:"item.reset",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.selected_shop = item;
          _vm.send_password_reset_request = true;}}},[_c('v-icon',[_vm._v("mdi-lock-reset")])],1)]}}],null,true)}),(_vm.send_password_reset_request)?_c('AdminDialog',{attrs:{"show_dialog":_vm.send_password_reset_request,"header":"Reset Password Request","loading":_vm.is_processing,"confirm_text":"Continue","confirm_color":"primary"},on:{"update:show_dialog":function($event){_vm.send_password_reset_request=$event},"confirm":function($event){return _vm.send_shop_admin_reset_password_email(_vm.selected_shop.id)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"mb-0"},[_vm._v(" Are you sure you want to send a password reset link to "),_c('b',[_vm._v(" "+_vm._s(_vm.selected_shop.name)+" ")]),_vm._v("? ")])]},proxy:true}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }